<template>
	<router-link :to="{ name: 'RequestsCategory', params: { id: slug } }" class="request-block">
		<div class="request-block-content">
			<span class="eyebrow">{{ category }}</span>
			<h4>{{ title }}</h4>
		</div>
		<div class="request-block-media">
			<BasketIcon class="basket" style="width: 26px;" v-if="category === 'Shopping'" />
			<PositionIcon class="position" style="width: 26px;" v-if="category === 'Visit'" />
			<LoveIcon class="love" style="width: 26px;" v-if="category === 'Contact'" />
			<CarIcon class="car" style="width: 26px;" v-if="category === 'Transport'" />
			<ScaleIcon class="scale"  style="width: 26px;" v-if="category === 'Justice & Welfare'" />
			<QuestionIcon class="question" style="width: 26px;" v-if="category === 'Other'" />
		</div>
	</router-link><!-- card -->
</template>

<script>
import BasketIcon from '@/assets/basket.svg';
import PositionIcon from '@/assets/position.svg';
import LoveIcon from '@/assets/love.svg';
import CarIcon from '@/assets/car.svg';
import ScaleIcon from '@/assets/scale.svg';
import QuestionIcon from '@/assets/question.svg';
export default {
	name: 'RequestBlock',
	props: {
		title: String,
		category: String,
        slug: String
	},
	components: {
		BasketIcon,
		PositionIcon,
		LoveIcon,
		CarIcon,
		ScaleIcon,
		QuestionIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.request-block {
		position: relative;
		display: flex;
		margin-right: 14px;
		font-size: 13px;
		line-height: 17px;
		font-weight: $weight_600;
		height: 160px;
		width: 150px;
		flex-direction: column;
		background: $snow;
		border-radius: 12px;
		padding: 17px;
		justify-content: space-between;
		flex-shrink: 0;
		text-align: left;
		&:last-of-type {
			margin-right: 0;
		}
		.request-block-content {

		}
		.request-block-media {

		}
		span {
			color: $slate;
		}
		h4 {
			font-size: 17px;
			color: $slate;
			line-height: 20px;
		}
		svg g, svg path {
			stroke: $slate;
		}
		svg .fill {
			fill: $slate;
			stroke: none;
		}
		svg.basket {
			g, path {
				stroke: $cyan_dark;
			}
		}
		svg.position {
			g, path {
				stroke: $purple_dark;
			}
		}
		svg.love {
			g, path {
				stroke: $red_dark;
			}
		}
		svg.car {
			g, path {
				stroke: $blue_dark;
			}
		}
		svg.scale {
			g, path {
				stroke: $orange_dark;
			}
		}
		svg.question {
			g, path, circle {
				stroke: $slate;
			}
		}
	}
</style>
